/**
 * @author rsouza
 * @since 1.0 (2021-09-18)
 * 
 * @description 
 *    Modulo/plugin nosso para reutilizacao no projeto.
 *    Sera instanciado automaticamente pelo vue para ser 
 *    usado em escopo global na pagina e como boas praticas e 
 *    padronizacao estamos usando o prefixo VS_ em constantes/variaveis e $vs em metodos/funcoes.
 *    exemplos de uso:
 * 
 *        console.log(this.VS_VARIAVEL)
 *        this.$vsMetodo(...)
 * 
 *    OBS: no escopo da tag <script> do componente o this eh necessario 
 *         no escopo da tag <template> do componente o this nao eh necessario e 
 *         pode ate causar conflito em sub-escopos (v-for por exemplo)
 */

export default {
    // #region configs
    name: "VisualSetPlugin",
    // #endregion

    // #region triggers
    install(Vue, options) {

        Vue.mixin({
            // #region dados
            data() {
                return {
                    /* USUARIOS VISUALSET */
                    VS_PERFIL_SUPERADMIN: 1,
					VS_PERFIL_DIRETORIA: 2,
                    VS_PERFIL_SUPORTE: 10,
					VS_PERFIL_COMERCIAL: 11,

                    /* USUARIOS DO CLIENTE */
                    VS_PERFIL_CONTRATANTE: 20,
                    VS_PERFIL_ADMIN: 30,
                    VS_PERFIL_GERENTE: 40,
                    VS_PERFIL_OPERADOR: 50,
                    VS_PERFIL_COLETOR: 60,
                    VS_PERFIL_POSTADOR: 70,
                    VS_PERFIL_REMETENTE: 80,
                    VS_PERFIL_DESTINATARIO: 90,
                    VS_PERFIL_FILIAL: 110,
                    VS_PERFIL_FRANQUIA: 120,
                    VS_PERFIL_PARCEIRO: 130,
                    VS_PERFIL_CLIENTE: 140,

                    /* USUARIO GENERICO/LEITURA */
                    VS_PERFIL_VISITANTE: 100,

                    /* DADOS DO PROJETO (MUDAR PARA PEGAR DO SERVIDOR) */
                    VS_PROJETO_ANO: (new Date).getFullYear(),
                    VS_PROJETO_VERSAO: "1.0",

                    /* TIPOS DE ENDERECO */
                    VS_TIPO_ENDERECO_RUA: 10,
                    VS_TIPO_ENDERECO_AVENIDA: 20,
                    VS_TIPO_ENDERECO_VIELA: 30,
                    VS_TIPO_ENDERECO_RODOVIA: 40,
                    VS_TIPO_ENDERECO_ALAMEDA: 50,

                    /* TIPOS DE VOLUMES */
                    VS_TIPO_VOLUME_CAIXA: 10,
                    VS_TIPO_VOLUME_ENVELOPE: 20,
                    VS_TIPO_VOLUME_CILINDRO: 30,

                    /* TIPOS DE CONTRATO */
                    VS_CONTRATO_TIPO_FIDELIDADE: 10,
                    VS_CONTRATO_TIPO_PREPAGO: 20,
                    VS_CONTRATO_TIPO_POSPAGO: 30,

                    /* STATUS DE CONTRATO */
                    VS_CONTRATO_STATUS_ATIVO: 10,
                    VS_CONTRATO_STATUS_INATIVO: 20,
                    VS_CONTRATO_STATUS_SUSPENSO: 30,

                    /* PERIODOS DE CONTRATO */
                    VS_CONTRATO_PERIODO_SEMANAL: 10,
                    VS_CONTRATO_PERIODO_QUINZENAL: 20,
                    VS_CONTRATO_PERIODO_MENSAL: 30,

                    /* METODOS DE PAGAMENTO */
                    VS_PAGAMENTO_CREDITO: 10,
                    VS_PAGAMENTO_DEBITO: 20,
                    VS_PAGAMENTO_DINHEIRO: 30,
                    VS_PAGAMENTO_SALDO: 40,
                    VS_PAGAMENTO_PIX: 50,
					VS_PAGAMENTO_FATURADO: 60,

                    /* OPERACOES BALCAO: CATEGORIAS */
                    VS_OPERACAO_BALCAO_CATEGORIA_PRODUTO: 1,
                    VS_OPERACAO_BALCAO_CATEGORIA_SERVICO: 2,

                    /* OPERACOES BALCAO: VALORES */
                    VS_OPERACAO_BALCAO_TIPO_VALOR_SEMVALOR: 1,
                    VS_OPERACAO_BALCAO_TIPO_VALOR_LIVRE: 2,
                    VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO: 3,

                    /* OPERACOES BALCAO: DESCONTOS */
                    VS_OPERACAO_BALCAO_DESCONTO_PERCENTUAL_MAXIMO: 30,

                    /* TIPOS DE RELATORIO */
                    VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_A4: 1,
                    VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_10x15: 2,

					/* ORIGEM DE CADASTRO */
					VS_ORIGEM_CADASTRO_AUTOCADASTRO: 1,
					VS_ORIGEM_CADASTRO_INTERNO: 2,
					VS_ORIGEM_CADASTRO_ATENDIMENTO: 3,

					/* STATUS DE RASTREIO DA POSTAGEM */
					VS_STATUS_RASTREIO_POSTADO: 1,
					VS_STATUS_RASTREIO_NAOPOSTADO: 2,
					VS_STATUS_RASTREIO_NAORASTREAVEL: 3,

					/* CATEGORIA DE ENCOMENDA DA POSTAGEM */
					VS_CATEGORIA_ENCOMENDA_POSTAGEM: 1,
					VS_CATEGORIA_ENCOMENDA_SERVICO: 2,
					VS_CATEGORIA_ENCOMENDA_PRODUTO: 3,

					/* STATUS DA FATURA */
					VS_STATUS_FATURA_ABERTA: 1,
					VS_STATUS_FATURA_PAGA: 2,
					VS_STATUS_FATURA_PAGAMENTO_PARCIAL: 3,
					VS_STATUS_FATURA_CANCELADA: 4,
					VS_STATUS_FATURA_VENCIDA: 5,

					/* STATUS DOS LANCAMENTOS DA FATUA */
					VS_STATUS_LANCAMENTO_FATURA_NAO_FATURADO: 1,
					VS_STATUS_LANCAMENTO_FATURA_FATURADO: 2,
					VS_STATUS_LANCAMENTO_FATURA_EXCLUIDO: 3,

					/* ORIGEM DE CANCELAMENTO DE VOLUME */
					VS_ORIGEM_CANCELAMENTO_VOLUME_TELA_ATENDIMENTO: 1,
					VS_ORIGEM_CANCELAMENTO_VOLUME_TELA_MOVIMENTACAO: 2,
					VS_ORIGEM_CANCELAMENTO_VOLUME_TELA_PREPOSTAGEM: 3,

					/* STATUS DE CONVITES PARA USUARIOS */
					VS_STATUS_USUARIO_CONVITE_AGUARDANDO: 0,
					VS_STATUS_USUARIO_CONVITE_ACEITO: 1,
					VS_STATUS_USUARIO_CONVITE_RECUSADO: 2,

					/* USO GERAL */
					VS_ETIQUETA_CORREIO_LEN: 13,
					VS_DIMENSAO_MINIMA_ALTURA: 1,
					VS_DIMENSAO_MINIMA_LARGURA: 10,
					VS_DIMENSAO_MINIMA_COMPRIMENTO: 15,
					VS_LIMITE_PAGINACAO: 1000,

					/* STATUS DE PRE POSTAGEM */
					VS_STATUS_PRE_POSTAGEM_ATENDIDO: 10,
					VS_STATUS_PRE_POSTAGEM_NAO_ATENDIDO: 20,

					/* CONTEXTO DE FATURA DE-PARA */
					VS_FATURA_DEPARA_CONTRATANTE_FILIAL: 10,
					VS_FATURA_DEPARA_FILIAL_CLIENTE: 20,
					VS_FATURA_DEPARA_CONTRATANTE_CLIENTE: 30,
                }
            },
            // #endregion
        })

        // #region metodos
        /**
         * @author mnunes
         * @author rsouza (ajustado em 2021-10-21)
         * @since 1.0 (2021-09-19)
         * 
         * @param   {string} num
         * @returns {string}
         */
        Vue.prototype.$vsFormataTelefone = function (num) {
            if (!num) {
                return ""
            }

            num = String(num).replace(/(\D)+/gim, '')

            if (num.length >= 3 && num.length < 7) {
                num = `(${num.substring(0, 2)}) ${num.substring(2)}`
            } else if (num.length >= 7) {
                let pos = (num.length < 11) ? 6 : 7
                num = `(${num.substring(0, 2)}) ${num.substring(2, pos)}-${num.substring(pos, 11)}`
            }
            return num
        }

        /**
         * @author mnunes
         * @author rsouza (ajustado em 2021-10-21)
         * @since 1.0 (2021-09-19)
         * 
         * @param   {string} num 
         * @returns {string}
         */
        Vue.prototype.$vsFormataCpfCnpj = function (num) {
            if (!num) {
                return ""
            }

            num = String(num).replace(/(\D)+/gim, '')

            if (num.length >= 13) {
                num = `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}/${num.substring(8, 12)}-${num.substring(12, 14)}`
            } else if (num.length >= 12) {
                num = `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}/${num.substring(8)}`
            } else if (num.length >= 10) {
                num = `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6, 9)}-${num.substring(9)}`
            } else if (num.length >= 7) {
                num = `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6)}`
            } else if (num.length >= 4 && num.length < 7) {
                num = `${num.substring(0, 3)}.${num.substring(3)}`
            }
            return num
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-19)
         * 
         * @param   {string} str
         * @param   {string} [regex=/(\D)+/gim]
         * @param   {string} [troca='']
         * @returns {string}
         */
        Vue.prototype.$vsLimpaFormatacao = function (str, regex = /(\D)+/gim, troca = '') {
            if (!str) {
                return ""
            }
            return str.replace(regex, troca)
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-23)
         * 
         * @param   {string} msg
         * @param   {string} [tipo="default"] - variant do bootstrap: default, info, success, danger, etc
         * @returns {void}
         */
        Vue.prototype.$vsNotificacao = function (msg, tipo = "default") {
            let tempoSegundos = (this.$vsAmbienteProducao()) ? 60 : 5

            this.$root.$bvToast.toast(msg, {
                variant: tipo,
                title: "Informativo",
                autoHideDelay: tempoSegundos * 1000,
                noCloseButton: false,
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: true, // antigas primeiro
            })
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-24)
         * 
         * @param   {string} msg
         * @param   {string} [tipo="default"] - variant do bootstrap: default, info, success, danger, etc
         * @param   {string} [httpLink=""] - link completo com http/https
         * @param   {string} [titulo="VisualSet News!"]
         * @returns {void}
         */
        Vue.prototype.$vsNotificacaoFixa = function (msg, tipo = "default", httpLink = "", titulo = "PostAir Informa") {
            this.$root.$bvToast.toast(msg, {
                title: titulo,
                variant: tipo,
                noAutoHide: true,
                toaster: "b-toaster-top-left tst1",
                href: httpLink,
                solid: true,
                appendToast: true, // antigas primeiro
            })
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-24)
         * 
         * @param   {string} number
         * @returns {boolean}
         */
        Vue.prototype.$vsCpfCnpjValido = function (number) {
            return this.$vsCpfValido(number) || this.$vsCnpjValido(number)
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-26)
         * 
         * @param   {string} cpf
         * @returns {boolean}
         */
        Vue.prototype.$vsCpfValido = function (cpf) {
            let regex = /[\d]{11}/

            if (!cpf || !regex.test(cpf)) {
                return false
            }

            let nums = Array.from(cpf)
            let cpfRepetido = String(nums[0]).repeat(11)

            if (cpfRepetido === cpf) {
                return false
            }

            let soma = 0
            let peso = 10

            for (let i = 0; i < 9; ++i) {
                soma += parseInt(nums[i]) * peso--
            }

            let resto1 = (soma * 10) % 11
            if (resto1 === 10) resto1 = 0

            soma = 0
            peso = 11

            for (let i = 0; i < 10; ++i) {
                soma += parseInt(nums[i]) * peso--
            }

            let resto2 = (soma * 10) % 11
            if (resto2 === 10) resto2 = 0

            let cpfGerado = nums.join('').substring(0, 9) + resto1 + resto2
            return (cpfGerado === cpf)
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-26)
         * 
         * @param   {string} cnpj
         * @returns {boolean}
         */
        Vue.prototype.$vsCnpjValido = function (cnpj) {
            let regex = /[\d]{14}/

            if (!cnpj || !regex.test(cnpj)) {
                return false
            }

            let nums = Array.from(cnpj)
            let cnpjRepetido = String(nums[0]).repeat(14)

            if (cnpjRepetido == cnpj) {
                return false
            }

            let soma = 0
            let peso = 5

            for (let i = 0; i < 12; ++i) {
                soma += parseInt(nums[i]) * peso--
                peso = (peso == 1) ? 9 : peso
            }
            let resto1 = soma % 11
            resto1 = (resto1 < 2) ? 0 : parseInt(Math.abs(11 - resto1))

            soma = 0
            peso = 6

            for (let i = 0; i < 13; ++i) {
                soma += parseInt(nums[i]) * peso--
                peso = (peso == 1) ? 9 : peso
            }
            let resto2 = soma % 11
            resto2 = (resto2 < 2) ? 0 : parseInt(Math.abs(11 - resto2))

            let cnpjGerado = nums.join('').substring(0, 12) + resto1 + resto2
            return (cnpjGerado == cnpj)
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-24)
         * 
         * @param   {string} number
         * @returns {boolean}
         */
        Vue.prototype.$vsTelefoneValido = function (number) {
            var regex = /[\d]{10,11}/
            return (number && number.length > 0 && regex.test(number))
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-24)
         * 
         * @param   {string} str
         * @param   {string} [str2=""] - para os casos de senha e confirmacao
         * @returns {boolean}
         */
        Vue.prototype.$vsSenhaValida = function (str, str2 = "") {
            let regex = /^.*(\d+[aA-zZ\u00C0-\u00FF]+|[aA-zZ\u00C0-\u00FF]+\d+).*$/ // ao menos 1 letra e 1 numero
            return !(str.length < 8 || !regex.test(str) || (str2.length > 0 && str !== str2))
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-24)
         * 
         * @param   {string} str
         * @returns {boolean}
         */
        Vue.prototype.$vsEmailValido = function (str) {
            // RFC 5322
			let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
            return regex.test(str)
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-24)
         * 
         * @param   {string} str
         * @returns {boolean}
         */
        Vue.prototype.$vsNomeValido = function (str) {
            //let regex = atendimento
            //    ? /^([a-zà-ú0-9]){1,}((\u0020)|([a-zà-ú0-9]))*$/gim // regra simples (bloqueia chars especiais)
            //    : /^([a-zà-ú]){3,}((\u0020){1,}([a-zà-ú])+)*$/gim   // regra completa (bloqueia chars especiais e numeros)
			let regex = /^([a-zà-ú0-9]){1,}((\u0020)|([a-zà-ú0-9]))*$/gim // (regra simples para todo o sistema 09/05/2023)
            return regex.test(str.trim())
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-25)
         * 
         * @description Requisicao http devolvendo uma Promise tratada 
         * para vc manipular os dados da forma que precisa/deseja
         * 
         * OBS: o cabecalho pode ser expandido, dependendo da necessidade do endpoint
         * (exemplo em vs-form-funciionario.vue usando formData)
         * 
         * OBS2: esse metodo se encarrega de ativar/desativar o loading
         * e caso seja identificado o token de autenticacao, ele tambem 
         * adiciona o cabecalho adequado...
         * 
         * @async
         * @param   {string}               metodo
         * @param   {string}               endPoint
         * @param   {object|Array<object>} [corpo={}|[]]
         * @param   {object}               [cabecalho={}]
         * @param   {string}               [tipoRetorno="json"]
         * @returns {Promise<object>}
         */
        Vue.prototype.$vsApiRest = async function (metodo, endPoint, corpo = {}, cabecalho = {}, tipoRetorno = "json", exibeMensagemErro = true) {
            this.$vsLoadingStart()
			let resp = null

            try {
				// http 200-299 OK
                resp = await this.$http({
                    method: metodo,
                    url: endPoint,
                    data: corpo,
                    headers: cabecalho,
                    responseType: tipoRetorno,
                })
				resp.responseType = tipoRetorno
            } catch (erro) {
				// http != 200-299 ERRO
				erro.responseType = tipoRetorno
				resp = this.$vsTrataErroHttp(erro, exibeMensagemErro)
            }
			this.$vsLoadingStop()
			return resp
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-30)
         * 
         * @returns {object|null}
         */
        Vue.prototype.$vsRecuperaLogin = function () {
            let obj = null
            if (localStorage.login) {
                obj = JSON.parse(this.$vsRecuperaLocal("login"))
            }
            return obj
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-30)
         * 
         * @param   {object} json
         * @returns {void}
         */
        Vue.prototype.$vsSalvaLogin = function (json) {
            localStorage.removeItem("login")
            if (json.isConectado) {
                this.$vsSalvaLocal("login", JSON.stringify(json))
            }
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-30)
         * 
         * @param   {object} form
         * @returns {void}
         */
        Vue.prototype.$vsLogin = function (json) {
			json.primeiroNome = json.nome.split(' ')[0]
			json.contratante = null
			json.filial = null

			if (!json.perfil) {
				json.perfil = this.VS_PERFIL_VISITANTE
			}
			if (!json.cargo) {
				json.cargo = "visitante"
			}
			sessionStorage.clear()
			this.$vsSalvaSessao(json) // bkp em caso de refresh (ver main.js)
			this.$router.push("/home")
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-09-30)
         * 
		 * @param   {string} [pagina="/"]
         * @returns {void}
         */
        Vue.prototype.$vsLogout = function (pagina = "/") {
			sessionStorage.clear()
			this.$root.sessao = null
			this.$router.push(pagina)
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-10-05)
         * 
         * @description Ajuda recuperar a sessao caso a pagina seja recarregada
         * 
         * @returns {void}
         */
        Vue.prototype.$vsRecuperaSessao = function () {
            if (sessionStorage.sessao) {
                this.$root.sessao = JSON.parse(this.$vsBase64Decode(sessionStorage.getItem("sessao")))
            }
            if (this.$router.currentRoute.meta.requireAuth && !this.$root.sessao) {
                this.$vsNotificacao("Sua sessão expirou, por gentileza efetue seu login novamente", "warning")
                this.$vsLogout()
            }
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-12-16)
         * 
         * @description Formata moeda para real brasileiro
         * 
         * @param   {string|number} num
         * @returns {string}
         */
        Vue.prototype.$vsFormataMoeda = function (num) {
            if (!num) {
                return 0
            }
            if (typeof (num) === "number") {
                num = num.toFixed(2)
            }

            let pos = num.indexOf('-')
            let sinal = num.substring(pos, pos + 1)
            num = parseInt(num.replace(/(\D)+/gim, '')) // remove sinal, formato e zeros na frente

            if (isNaN(num)) {
                return (sinal === '-') ? "-0,00" : "0,00"
            }

            num = String(num)

            if (num.length > 14) {
                num = num.substring(0, 14) // define limite (bilhao)
            }

            let centavos = num.substring(num.length - 2)

            if (num.length === 1) {
                num = `0,0${num.substring(0)}`
            } else if (num.length === 2) {
                num = `0,${num.substring(0)}`
            } else if (num.length >= 3 && num.length < 6) {
                num = `${num.substring(0, num.length - 2)},${centavos}`
            } else if (num.length === 6) {
                num = `${num.substring(0, 1)}.${num.substring(1, 4)},${centavos}`
            } else if (num.length === 7) {
                num = `${num.substring(0, 2)}.${num.substring(2, 5)},${centavos}`
            } else if (num.length === 8) {
                num = `${num.substring(0, 3)}.${num.substring(3, 6)},${centavos}`
            } else if (num.length === 9) {
                num = `${num.substring(0, 1)}.${num.substring(1, 4)}.${num.substring(4, 7)},${centavos}`
            } else if (num.length === 10) {
                num = `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)},${centavos}`
            } else if (num.length === 11) {
                num = `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6, 9)},${centavos}`
            } else if (num.length === 12) {
                num = `${num.substring(0, 1)}.${num.substring(1, 4)}.${num.substring(4, 7)}.${num.substring(7, 10)},${centavos}`
            } else if (num.length === 13) {
                num = `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}.${num.substring(8, 11)},${centavos}`
            } else if (num.length === 14) {
                num = `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6, 9)}.${num.substring(9, 12)},${centavos}`
            }
            if (sinal === '-') {
                num = `-${num}`
            }
            return num
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-12-16)
         * 
         * @description Converte string para float
         * 
         * @param   {string|number} num
         * @returns {number}
         */
        Vue.prototype.$vsConverteMoeda = function (num) {
            if (!num) {
                return 0
            }

            num = String(num)

            let signal = num[0]
            num = num.replace(/(\D)+/gim, '') // remove formato

            if (num.length > 14) {
                num = num.substring(0, 14) // define limite (bilhao)
            }

            let centavos = num.substring(num.length - 2)

            if (num.length > 2) {
                num = `${num.substring(0, num.length - 2)}.${centavos}`
            }

            if (signal === '-') {
                num = `-${num}`
            }
            return parseFloat(num)
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-12-17)
         * 
         * @description Formata data conforme localizacao
         * 
         * @param   {string}  str
         * @param   {boolean} [exibirHora=false]
         * @param   {string}  [idioma="pt-BR"]
         * @returns {string}
         */
        Vue.prototype.$vsFormataData = function (str, exibirHora = false, idioma = "pt-BR") {
            if (!str || str.length === 0) {
                return ""
            }

            let padraoServidor = "0001-01-01"
            let data = str.split('T')

            if (data.length === 0 || data[0] === padraoServidor) {
                return ""
            }

            if (data.length === 1) {
                data[1] = "00:00:00Z"
            }

            let date = new Date(data.join('T'))
            let isValid = (date instanceof Date && isFinite(date))

            if (!isValid) {
                return ""
            }

            if (exibirHora) {
                return `${date.toLocaleDateString(idioma)} ${date.toLocaleTimeString(idioma)}`
            }
            return date.toLocaleDateString(idioma)
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-12-21)
         * 
         * @description Lista contratos vigentes para uso
         * 
         * @param   {number} [codigo=0]
         * @returns {Array<object>|object|null}
         */
        Vue.prototype.$vsListaContratos = function (codigo = 0) {
            let lista = [
                { value: this.VS_CONTRATO_TIPO_FIDELIDADE, text: "Fidelidade", icon: "cash" },
                //{ value: this.VS_CONTRATO_TIPO_PREPAGO, text: "Pré Pago", icon: "wallet"      },
                { value: this.VS_CONTRATO_TIPO_POSPAGO, text: "Pós Pago", icon: "credit-card2-front" },
            ]
            if (codigo > 0) {
                return lista.find(el => el.value === codigo) || null
            }
            return lista
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-12-22)
         * 
         * @param   {object} json
         * @returns {void}
         */
        Vue.prototype.$vsSalvaSessao = function (json) {
            sessionStorage.setItem("sessao", this.$vsBase64Encode(JSON.stringify(json)))
			this.$root.sessao = json
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-12-23)
         * 
         * @param   {string} num
         * @returns {string}
         */
        Vue.prototype.$vsFormataCep = function (num) {
            if (!num) {
                return ""
            }

            num = num.replace(/(\D)+/gim, '')

            if (num.length <= 5) {
                num = num.substring(0, 5)
            } else if (num.length > 5) {
                num = `${num.substring(0, 5)}-${num.substring(5, 8)}`
            }
            return num
        }


        /**
         * @author rsouza
         * @since 1.0 (2021-12-23)
         * 
         * @param   {number} [codigo=0]
         * @returns {Array<object>|object|null}
         */
        Vue.prototype.$vsListaContratosPeriodo = function (codigo = 0) {
            let lista = [
                { value: this.VS_CONTRATO_PERIODO_SEMANAL, text: "Semanal" },
                { value: this.VS_CONTRATO_PERIODO_QUINZENAL, text: "Quinzenal" },
                { value: this.VS_CONTRATO_PERIODO_MENSAL, text: "Mensal" },
            ]
            if (codigo > 0) {
                return lista.find(el => el.value === codigo) || null
            }
            return lista
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-12-23)
         * 
         * @param   {number} [codigo=0]
         * @returns {Array<object>|object|null}
         */
        Vue.prototype.$vsListaContratosStatus = function (codigo = 0) {
            let lista = [
                { value: this.VS_CONTRATO_STATUS_ATIVO, text: "Ativo", class: "text-info", icon: "check", },
                { value: this.VS_CONTRATO_STATUS_INATIVO, text: "Inativo", class: "text-warning", icon: "dot", },
                { value: this.VS_CONTRATO_STATUS_SUSPENSO, text: "Suspenso", class: "text-danger", icon: "x", },
            ]
            if (codigo > 0) {
                return lista.find(el => el.value === codigo) || null
            }
            return lista
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-12-24)
         * 
         * @param   {Array} lista
         * @returns {void}
         */
        Vue.prototype.$vsContratosOrdenacao = function (lista) {
            let comparador = function (a, b) {
                if (a.tipo < b.tipo) {
                    return -1
                }
                if (a.tipo > b.tipo) {
                    return 1
                }
                return 0
            }
            lista.sort(comparador)
        }

        /**
         * @author rsouza
         * @since 1.0 (2021-12-24)
         * 
         * @description Lista formas de pagamento ou retorna o objeto pelo
         *     codigo informado, codigo PENDENTE é um coringa e 
         *     dependendo da situacao nao pode ser exibido
         * 
         * @param   {number}  [codigo=0]
         * @param   {boolean} [exibirSaldo=false]
		 * @param   {boolean} [exibirFaturado=false]
         * @returns {Array<object>|object|null}
         */
        Vue.prototype.$vsListaPagamentos = function (codigo = 0, exibirSaldo = false, exibirFaturado = false) {
            let lista = [
                { value: this.VS_PAGAMENTO_CREDITO, text: "Cartão de crédito", class: "text-info", icon: "credit-card-fill", },
                { value: this.VS_PAGAMENTO_DEBITO, text: "Cartão de Débito", class: "text-warning", icon: "credit-card", },
                { value: this.VS_PAGAMENTO_DINHEIRO, text: "Dinheiro", class: "text-danger", icon: "cash", },
                { value: this.VS_PAGAMENTO_PIX, text: "Pix", class: "text-danger", icon: "x-diamond-fill", },
            ]
            if (codigo === this.VS_PAGAMENTO_SALDO || exibirSaldo) {
                lista.push({ value: this.VS_PAGAMENTO_SALDO, text: "À faturar", class: "text-danger", icon: "credit-card2-front" })
            }
			if (codigo === this.VS_PAGAMENTO_FATURADO || exibirFaturado) {
				lista.push({ value: this.VS_PAGAMENTO_FATURADO, text: "Faturado", class: "text-danger", icon: "credit-card2-front-fill" })
			}
            if (codigo > 0) {
                return lista.find(el => el.value === codigo) || null
            }
            return lista
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-01-20)
         * 
         * @description Inicia o loading da aplicacao com base na rota atual
         * com opcao de auto parar...
         *
         * @param   {boolean} [autoStop=false] - valor padrao false
         * @param   {number}  [delayStop=0] - valor padrao 0 segundo
         * @returns {void}
         */
        Vue.prototype.$vsLoadingStart = function (autoStop = false, delayStop = 0) {
            this.$root.loading = true

            if (autoStop) {
                let vm = this
                setTimeout(() => {
                    vm.$vsLoadingStop()
                }, delayStop)
            }
        }

        /**
        * @author rsouza
        * @since 1.0 (2022-01-20)
        * 
        * @description Finaliza o loading da aplicacao
        *
        * @returns {void}
        */
        Vue.prototype.$vsLoadingStop = function () {
            this.$root.loading = false
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-02-08)
         * 
         * @description Lista tipos de embalagens de objetos
         * 
         * @param   {number} [codigo=0]
         * @returns {Array<object>|object|null}
         */
        Vue.prototype.$vsListaTiposObjetos = function (codigo = 0) {
            let lista = [
                { value: this.VS_TIPO_VOLUME_CAIXA, text: "Caixa", },
                { value: this.VS_TIPO_VOLUME_ENVELOPE, text: "Envelope", },
                { value: this.VS_TIPO_VOLUME_CILINDRO, text: "Cilindro", },
            ]
            if (codigo > 0) {
                return lista.find(el => el.value === codigo) || null
            }
            return lista
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-02-16)
         * 
         * @description Retorna string convertida em base64
         * (lógica retirada da mozilla: https://developer.mozilla.org/en-US/docs/Web/API/btoa)
         * 
         * @param   {string} str
         * @returns {string}
         */
        Vue.prototype.$vsBase64Encode = function (str) {
            if (!str || str.length <= 0) {
                return ""
            }

            const codeUnits = new Uint16Array(str.length)
            for (let i = 0; i < codeUnits.length; i++) {
                codeUnits[i] = str.charCodeAt(i)
            }
            const charCodes = new Uint8Array(codeUnits.buffer)
            let result = []
            for (let i = 0; i < charCodes.byteLength; i++) {
                result.push(String.fromCharCode(charCodes[i]))
            }
            return window.btoa(result.join(''))
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-02-16)
         * 
         * @description Retorna string original
         * (lógica retirada da mozilla: https://developer.mozilla.org/en-US/docs/Web/API/btoa)
         * 
         * @param   {string} strBin
         * @returns {string}
         */
        Vue.prototype.$vsBase64Decode = function (strBin) {
            if (!strBin || strBin.length <= 0) {
                return ""
            }

            const decoded = window.atob(strBin)
            const bytes = new Uint8Array(decoded.length)
            for (let i = 0; i < bytes.length; i++) {
                bytes[i] = decoded.charCodeAt(i)
            }
            const charCodes = new Uint16Array(bytes.buffer)
            let result = []
            for (let i = 0; i < charCodes.length; i++) {
                result.push(String.fromCharCode(charCodes[i]))
            }
            return result.join('')
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-02-16)
         * 
         * @description Salva o valor mascadarado (binario e base64) na chave informada
         * 
         * @param   {string} chave
         * @param   {string} valor
         * @returns {void}
         */
        Vue.prototype.$vsSalvaLocal = function (chave, valor) {
            localStorage.setItem(chave, this.$vsBase64Encode(valor))
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-02-16)
         * 
         * @description Recupera o valor desmascadarado (binario e base64) da chave informada
         * 
         * @param   {string} chave
         * @returns {string}
         */
        Vue.prototype.$vsRecuperaLocal = function (chave) {
            return this.$vsBase64Decode(localStorage.getItem(chave))
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-03-27)
         * 
         * @description Formata hora conforme localizacao
         * 
         * @param   {string}  str
         * @param   {boolean} [segundos=false]
         * @returns {string}
         */
        Vue.prototype.$vsFormataHora = function (str, segundos = false) {
            let data = this.$vsFormataData(str, true)
            if (!data || data.length === 0) {
                return ""
            }
            let hora = data.split(' ')[1]
            return segundos ? hora : hora.substring(0, 5)
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-04-21)
         * 
         * @description Imprime em formato HTML
         * 
         * @param   {object} elementoHTML
         * @returns {void}
         */
        Vue.prototype.$vsImprimeHTML = function (elementoHTML) {
            this.$vsLoadingStart()
            let divOculta = document.getElementById("impressaoHtml")
            let html = elementoHTML.cloneNode(true)
            html.id = ""
            if (divOculta.firstChild) {
                divOculta.firstChild.remove()
            }
            divOculta.appendChild(html)
            window.print()
            this.$vsLoadingStop()
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-08-06)
         * 
         * @description Forca o navegador imprimir os bytes retornados do servidor
         * 
		 * @async
         * @param   {string} etiqueta
         * @returns {void}
         */
        Vue.prototype.$vsImprimeEtiqueta = async function (etiqueta) {
            let resp = await this.$vsApiRest("GET", `/postagens/vipp/${etiqueta.toUpperCase()}`, {}, {}, "arraybuffer")
                
			if (resp.status === 200) {
               	this.$vsDownload(resp.data, `etiqueta-postair-${etiqueta.toUpperCase()}.pdf`)
            }
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-09-08)
         * 
         * @description Imprime em formato PDF
         * 
         * @param   {object} elementoHTML
         * @param   {string} [nomeArquivo="arquivo.pdf"]
         * @returns {void}
         */
        Vue.prototype.$vsImprimePDF = function (elementoHTML, nomeArquivo = "arquivo.pdf") {
            let vm = this
            vm.$vsLoadingStart()
            let pdf = new this.$pdf("p", "pt", "a4")

            pdf.html(elementoHTML, {
                callback: function (pdf) {
                    //pdf.output("dataurlnewwindow", nomeArquivo)
                    let buffer = pdf.output("arraybuffer", nomeArquivo)
                    vm.$vsDownload(buffer, nomeArquivo)
                    vm.$vsLoadingStop()
                },
                autoPaging: true,
                margin: [10, 10, 10, 10],
                html2canvas: { scale: 0.8 },
            })
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-09-09)
         * 
         * @description Navegacao com validacao de login
         * 
         * @returns {void}
         */
        Vue.prototype.$vsNavegacaoRota = function () {
            this.$router.beforeEach((to, from, next) => {
                let path = {}
                if (to.meta.requireAuth && !this.$root.sessao) {
                    path = { path: "/logout" }
                }
                next(path)
            })
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-09-08)
         * 
         * @description Forca download do arquivo em extensao propria
         * 
         * @param   {ArrayBuffer} buffer
         * @param   {string}      [nomeArquivo="arquivo.vst"]
         * @returns {void}
         */
        Vue.prototype.$vsDownload = function (buffer, nomeArquivo = "arquivo.vst", contentType = "application/octet-stream") {
            // forca download com link oculto
            let url = window.URL.createObjectURL(new Blob([buffer], { type: contentType }))
            let link = document.createElement("a")
            link.style.display = "none"
            link.href = url
            link.download = nomeArquivo
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-10-25)
         * 
         * @description Lista categorias para operacoes de balcao
         * 
         * @param   {number} [codigo=0]
         * @returns {Array<object>|object|null}
         */
        Vue.prototype.$vsListaOperacaoBalcaoCategorias = function (codigo = 0) {
            let lista = [
                { value: this.VS_OPERACAO_BALCAO_CATEGORIA_PRODUTO, text: "Produto", },
                { value: this.VS_OPERACAO_BALCAO_CATEGORIA_SERVICO, text: "Serviço", },
            ]
            if (codigo > 0) {
                return lista.find(el => el.value === codigo) || null
            }
            return lista
        }

        /**
         * @author rsouza
         * @since 1.0 (2022-10-25)
         * 
         * @description Lista tipos de valor para operacoes de balcao
         * 
         * @param   {number} [codigo=0]
         * @returns {Array<object>|object|null}
         */
        Vue.prototype.$vsListaOperacaoBalcaoTipoValor = function (codigo = 0) {
            let lista = [
                { value: this.VS_OPERACAO_BALCAO_TIPO_VALOR_SEMVALOR, text: "Sem valor", },
                { value: this.VS_OPERACAO_BALCAO_TIPO_VALOR_LIVRE, text: "Livre", },
                { value: this.VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO, text: "Fixo", },
            ]
            if (codigo > 0) {
                return lista.find(el => el.value === codigo) || null
            }
            return lista
        }

        /**
         * @author rsouza
         * @since 1.0 (2023-01-09)
         * 
         * @description Valida acesso
         * 
         * @param   {Array<Number>} [perfisPermitidos]
         * @returns {void}
         */
        Vue.prototype.$vsDefineAcesso = function (perfisPermitidos) {
            let sessao = this.$root.sessao

            if (!perfisPermitidos.includes(sessao.perfil)) {
                this.$router.push(sessao.home)
            }
        }

        /**
         * @author rsouza
         * @since 1.0 (2023-04-06)
         * 
         * @description Converte data local em UTC
         * 
         * @param   {string} dataLocal
         * @returns {string}
         */
        Vue.prototype.$vsConverteDataUTC = function (dataLocal) {
            if (!dataLocal) {
                return dataLocal
            }
            return new Date(dataLocal).toISOString()
        }

        /**
         * @author rsouza
         * @since 1.0 (2023-04-21)
         * 
         * @description Trigger para esconder o menu ao clicar em qualquer lugar
         * 
         * @param   {string} dataLocal
         * @returns {string}
         */
        Vue.prototype.$vsEscondeMenu = function () {
            this.$root.mostraMenu = false
        }

		/**
         * @author rsouza
         * @since 1.0 (2023-05-31)
         * 
         * @description Formata numero com pontos de milhar
         * 
         * @param   {string|number} num
         * @returns {string}
         */
        Vue.prototype.$vsFormataNumero = function (num) {
			num = this.$vsFormataMoeda(num)
			try {
				num = num.split(",")[0]
			} catch (ex) {}
			return num
        }

		/**
         * @author rsouza
         * @since 1.0 (2023-07-14)
         * 
         * @description Converte html em texto
         * 
         * @param   {string} html
         * @returns {string}
         */
        Vue.prototype.$vsConverteHtmlTexto = function (html) {
			let maxlen = 45
			let camposADireita = [
				"ITEM:", "VOLUME:",	"VALOR DECLARADO:", "VALOR NF:", "PESO (g):", 
                "DIMENSÕES (AxLxC cm):", "QTD:", "VALOR:", "SUB-TOTAL:", "TOTAL:",
                "DESCONTO:", "TOTAL COM DESCONTO:"
			]			
			let text = this.$root.$conversorTexto(html, { wordwrap: maxlen })
			let lines = text.split("\n")

			for (let i = 0; i < lines.length; ++i) {
				let pos = lines[i].split(":")
				let key = `${pos[0]}:`

				if (camposADireita.includes(key)) {
                    let value = pos[1]

                    if (key == "DESCONTO:") {
                        let pos2 = pos[1].split(" ")
                        key = `DESCONTO ${pos2[0]}:`
                        value = pos2[1]
                    }
					lines[i] = `${key}${value.padStart(maxlen - key.length, " ")}`
				}

				lines[i] = this.$vsRemoveAcentos(lines[i])

				if (lines[i].includes("===") || lines[i].includes("---")) {
					lines[i] = lines[i].substring(0, maxlen)
				}
			}

			let linhaCorte = "-".repeat(maxlen)

            //return `\r\n\r\n${linhaCorte}\r\n\r\n\r\n${lines.join("\r\n")}\r\n\r\n\r\n${linhaCorte}\r\n\r\n` // espaco para corte +2 linhas
			return `${lines.join("\r\n")}\r\n` // espaco para corte +2 linhas
        }

		/**
         * @author rsouza
         * @since 1.0 (2023-07-14)
         * 
         * @description Remove acentos do texto
         * 
         * @param   {string} str
         * @returns {string}
         */
		Vue.prototype.$vsRemoveAcentos = function (str) {
			return str
			  .replace(/[ÀÁÂÃÄÅ]/g,"A")
			  .replace(/[aáàãâä]/g,"a")
			  .replace(/[EÉÈÊË]/g,"E")
			  .replace(/[eéèêë&]/g,"e")
			  .replace(/[IÍÌÎÏ]/g,"I")
			  .replace(/[iíìîï]/g,"i")
			  .replace(/[OÓÒÕÔÖ]/g,"O")
			  .replace(/[oóòõôö]/g,"o")
			  .replace(/[UÚÙÛÜ]/g,"U")
			  .replace(/[uúùûü]/g,"u")
			  .replace(/[ç]/g,"c")
			  .replace(/[Ç]/g,"C")
			  .replace(/[¹²³ªº]/g, ".")
			  .replace(/[£¢¬§]/g, " ")
		}

		/**
         * @author rsouza
         * @since 1.0 (2023-10-11)
         * 
         * @description Retorna se o ambiente eh producao
         * 
         * @returns {boolean}
         */
        Vue.prototype.$vsAmbienteProducao = function () {
            let dns = window.location.hostname.toLowerCase().split(".")[0]
            return dns.includes("www") || dns.includes("postair")
        }

		/**
         * @author rsouza
         * @since 1.0 (2023-09-28)
         * 
         * @description Retorna se o ambiente eh QA (rota alternativa producao)
         * 
         * @returns {boolean}
         */
        Vue.prototype.$vsAmbienteQA = function () {
            let dns = window.location.hostname.toLowerCase().split(".")[0]
            return dns.includes("qa")
        }

		/**
         * @author rsouza
         * @since 1.0 (2023-08-17)
         * 
         * @description Retorna se o ambiente eh homologacao
         * 
         * @returns {boolean}
         */
        Vue.prototype.$vsAmbienteHomologacao = function () {
            let dns = window.location.hostname.toLowerCase().split(".")[0]
            return dns.includes("homo")
        }		

		/**
         * @author rsouza
         * @since 1.0 (2023-08-17)
         * 
         * @description Retorna se o ambiente eh teste
         * 
         * @returns {boolean}
         */
        Vue.prototype.$vsAmbienteTeste = function () {
            let dns = window.location.hostname.toLowerCase().split(".")[0]
            return dns.includes("dev")
        }

		/**
         * @author rsouza
         * @since 1.0 (2023-08-17)
         * 
         * @description Retorna se o ambiente eh desenvolvimento
         * 
         * @returns {boolean}
         */
        Vue.prototype.$vsAmbienteDesenvolvimento = function () {
            let dns = window.location.hostname.toLowerCase().split(".")[0]
            return (dns.includes("localhost") || dns.includes("192") || dns.includes("127"))
        }

		/**
         * @author rsouza
         * @since 1.0 (2023-09-09)
         * 
         * @description URL de API definida conforme ambiente de servidor
         * 
         * @returns {void}
         */
        Vue.prototype.$vsDefineUrlApi = function () {
            let protocolo = "https"
            let site = "www.postair.com.br" // prod

			if (this.$vsAmbienteQA()) {
				site = "qa.postair.com.br" // QA (rota alternativa producao)
			}
            if (this.$vsAmbienteHomologacao()) {
                site = "homo.postair.com.br"
            }
			if (this.$vsAmbienteTeste()) {
                site = "dev.postair.com.br"
            }
			if (this.$vsAmbienteDesenvolvimento()) {
				let porta = window.location.port
				protocolo = "http"
				
				if (window.location.port == 8080) {
					porta = 8000
				} 
                site = `${window.location.hostname}:${porta}`
            }
			this.$http.defaults.baseURL = `${protocolo}://${site}/api/v1`
        }

		/**
         * @author rsouza
         * @since 1.0 (2023-12-27)
         * 
         * @description Valida algoritmo etiqueta do correio
         * 
		 * @param   {string} etiqueta
         * @returns {boolean}
         */
        Vue.prototype.$vsValidaEtiquetaCorreio = function (etiqueta) {
			try {
				let regex = /[A-Z]{2}[\d]{9}[A-Z]{2}/
				return regex.test(etiqueta.trim().toUpperCase())
			} catch (err) {
				return false
			}
			
        }

		/**
         * @author rsouza
         * @since 1.0 (2024-01-02)
         * 
         * @description Define vinculo entre usuarios do sistema
         * 
		 * @param   {object}  sessao
		 * @param   {string}  idVinculo
		 * @param   {boolean} [remocao=false] 
         * @returns {boolean}
         */
        Vue.prototype.$vsDefineVinculoUsuario = function (sessao, idVinculo, remocao=false, padrao=false) {
			let vinculoExistente = sessao.vinculos.find(el => el.idUsuario == idVinculo)
			let ativo = (remocao === false)

			if (vinculoExistente) {
				vinculoExistente.isAtivo = ativo
			} else {
				sessao.vinculos.push({
					idUsuario: idVinculo,
					isSuperior: true,
					isAtivo: ativo,
				})
			}
			sessao.vinculos.forEach(el => {
				el.isPadrao = (el.idUsuario === idVinculo && padrao)
			})

			this.$vsSalvaSessao(sessao)
        }

		/**
         * @author rsouza
         * @since 1.0 (2024-02-27)
         * 
         * @description Forca o navegador imprimir os bytes retornados do servidor
         * 
		 * @async
         * @param   {string} etiqueta
         * @returns {void}
         */
        Vue.prototype.$vsImprimeEtiquetaPrePostagem = async function (etiqueta) {
            let resp = await this.$vsApiRest("GET", `/pre-postagens/vipp/${etiqueta.toUpperCase()}`, {}, {}, "arraybuffer")
                
			if (resp.status === 200) {
               	this.$vsDownload(resp.data, `etiqueta-postair-${etiqueta.toUpperCase()}.pdf`)
            }
        }

		/**
         * @author rsouza
         * @since 1.0 (2024-03-11)
         * 
         * @param   {string} fotoBase64 
         * @returns {string}
         */
        Vue.prototype.$vsDefineFoto = function (fotoBase64) {
			return (!fotoBase64 || !fotoBase64.length) 
				? require("@/assets/img/logo.png") : fotoBase64
        }

		/**
         * @author rsouza
         * @since 1.0 (2024-03-27)
		 * 
		 * @description Trata o erro da requisicao http/ajax
         * 
         * @param   {object}  erro
		 * @param   {boolean} [exibeMensagem=true]
         * @returns {string}
         */
        Vue.prototype.$vsTrataErroHttp = function (erro, exibeMensagem=true) {
			let resp = erro.response
			let msg = ""
			let msgTipo = "warning"

			// #region ajustes
			if (!resp) {
				resp = {
					status: 500,
                	data: { msg: "Servidor indisponível, por gentileza tente novamente mais tarde" }
				}
			}
			
			if (erro.responseType === "arraybuffer") {
				resp.data = JSON.parse(new TextDecoder().decode(resp.data))
			}
			// #endregion
			
			switch (resp.status) {
				// #region erros de cliente (400-499)
				case 400:
				case 403:
				case 404:
					msg = resp.data.msg
					msgTipo = "warning"
					break

				case 401:
					let obj = resp.data
					
					if (typeof(obj) === "string" && !obj.length) {
						msg = "Sua sessão expirou, efetue seu login novamente"
						msgTipo = "warning"
                    	this.$vsLogout()
					}
					if (!obj.preCadastro) {
						msg = resp.data.msg
						msgTipo = "warning"
					}
					break
				// #endregion

				// #region erros de servidor (500-599)
				case 502:
					msg = "Não foi possível processar sua requisição no momento, tente novamente mais tarde"
					msgTipo = "danger"
					exibeMensagem = true
					break

				case 503:
					this.$vsLogout("/manutencao")
					exibeMensagem = false
					break

				case 504:
					msg = "Tempo de requisição excedido, tente novamente mais tarde"
					msgTipo = "danger"
					exibeMensagem = true
					break

				case 500:
				default:
					msg = resp.data.msg
					msgTipo = "danger"
					exibeMensagem = true
					break
				// #endregion
			}
			if (exibeMensagem && msg.length) {
				this.$vsNotificacao(msg, msgTipo)
			}
			return resp
        }

		/**
         * @author rsouza
         * @since 1.0 (2024-04-01)
		 * 
		 * @description Valida autenticacao com base no perfil autorizado
         * 
		 * @async
         * @param   {string} login
		 * @param   {string} senha
		 * @param   {number} perfilAutorizado 
         * @returns {object|null}
         */
		Vue.prototype.$vsAutenticacao = async function (login, senha, perfilAutorizado) {
			if (!login || !login.trim().length || !senha || !senha.trim().length) {
				this.$vsNotificacao("Autenticação é necessária", "warning")
				return null
			}
			let obj = {
				email: login.trim(),
				senha: senha.trim(),
				perfil: perfilAutorizado,
			}
			return await this.$vsApiRest("POST", "/login/validacao", obj)
		}

		/**
         * @author rsouza
         * @since 1.0 (2024-04-17)
		 * 
		 * @description Customiza nome de elementos html dinamicamente por sessao (evita ataques/scraping, etc)
         * 
         * @param   {string} nome
         * @returns {string}
         */
		Vue.prototype.$vsDataVsNome = function(nome) {
			return this.$vsAmbienteProducao() ? "" : `${nome}`
		}

		/**
         * @author rsouza
         * @since 1.0 (2024-07-15)
		 * 
		 * @description Inibe a visualizacao do codigo fonte usando atalhos do teclado (ainda nao bloqueia opcoes diretas pela bara de menu do browser)
         * 
         * @returns {void}
         */
		Vue.prototype.$vsDificultaAcessoCodigoFonte = function() {
			if (this.$vsAmbienteProducao()) {
				document.addEventListener("contextmenu", event => event.preventDefault())
				document.addEventListener("keydown", event => {
					let F12 = (event.code === "F12")
					let CtrlS = (event.ctrlKey && event.code === "KeyS")
					let CtrlU = (event.ctrlKey && event.code === "KeyU")
					let CtrlShitI = (event.ctrlKey && event.shiftKey && event.code === "KeyI")

					if (F12 || CtrlShitI || CtrlU || CtrlS) {
						event.preventDefault()
					}
				})
				document.body.click()
			}
		}

		/**
         * @author rsouza
         * @since 1.0 (2024-07-15)
		 * 
		 * @description Start do projeto chamado em main.js (validacoes, travas, configuracoes iniciais, etc...)
         * 
         * @returns {void}
         */
		Vue.prototype.$vsBootstrap = function() {
			this.$vsDificultaAcessoCodigoFonte()
			this.$vsDefineUrlApi()
			this.$vsRecuperaSessao() // em caso de refresh
			this.$vsNavegacaoRota()  // em caso de navegacao via rotas/links
			this.$vsConfiguraInterceptadoresHttp()
		}

		/**
         * @author rsouza
         * @since 1.0 (2024-07-23)
		 * 
		 * @description Logica para testar/validar itens antes de subir em producao (exibe do ambiente QA para "baixo")
         * 
         * @returns {void}
         */
		Vue.prototype.$vsAguardandoValidacao = function() {
			return !this.$vsAmbienteProducao()
		}

		/**
         * @author rsouza
         * @since 1.0 (2024-08-19)
		 * 
		 * @description Isola configs e tratamentos no request/response http
         * 
         * @returns {void}
         */
		Vue.prototype.$vsConfiguraInterceptadoresHttp = function() {
			// #region request
			this.$http.interceptors.request.use(req => {
				req.headers["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone

                if (this.$root.sessao) {
					req.headers["Authorization"] = `Bearer ${this.$root.sessao.token}`
                }
				if (this.$vsAmbienteTeste() || this.$vsAmbienteDesenvolvimento()) {
					req.headers["X-Dev-Host"] = `${window.location.hostname}:${window.location.port}`
					req.headers["X-Dev-Production-Simulated"] = String(this.$root.baseProducaoSimulada)
				}
				return Promise.resolve(req)
			})
			// #endregion

			// #region response
			/*this.$http.interceptors.response.use(resp => {
				let chaveDeployLocal = "deploy"
				let deployServidor = resp.headers["x-deploy-date"] || ""
				let deployLocal = localStorage.getItem(chaveDeployLocal)
			
				if(deployServidor.length && deployLocal !== deployServidor){
					localStorage.setItem(chaveDeployLocal, deployServidor)
					this.$vsLogout()
					window.location.reload()
				}
				return Promise.resolve(resp)
			})*/
			// #endregion
		}
        // #endregion
    }
    // #endregion
}
