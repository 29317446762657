<template>
    <div>
        <h1 class="titulo">Gestão de faturas Cliente</h1>

        <!-- #region filtro -->
        <form @submit.prevent="listaFaturas()">
            <b-form-row>
                <b-col md="2">
                    <b-form-group class="form-input-cinza" label="Pesquisar por" label-cols="12" label-size="lg">
                        <b-form-select v-model="filtro.tipoData" :options="comboTipoData" @change="limpaTela()" />
                    </b-form-group>
                </b-col>

                <b-col md="2">
                    <b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
                        <b-form-datepicker locale="pt-BR" v-model="filtro.dataInicial" placeholder="dd/mm/aaaa" :date-format-options="formatoData" @input="limpaTela()" />
                    </b-form-group>
                </b-col>

                <b-col md="2">
                    <b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
                        <b-form-datepicker locale="pt-BR" v-model="filtro.dataFinal" placeholder="dd/mm/aaaa" :date-format-options="formatoData" @input="limpaTela()" />
                    </b-form-group>
                </b-col>

                <b-col v-if="[VS_PERFIL_FILIAL, VS_PERFIL_GERENTE].includes($root.sessao.perfil)" md="2">
                    <b-form-group class="form-input-cinza" label="Pagador" label-cols="12" label-size="lg">
                        <model-select v-model="filtro.idPagador" :options="comboPagador" @input="limpaTela()" />
                    </b-form-group>
                </b-col>

                <b-col md="1">
                    <b-form-group class="form-input-cinza" label="Situação" label-cols="12" label-size="lg">
                        <b-form-select v-model="filtro.situacao" :options="comboSituacao" @change="limpaTela()" />
                    </b-form-group>
                </b-col>

                <b-col md="2">
                    <b-form-group class="form-input-cinza" label="Fatura Nº" label-cols="12" label-size="lg">
                        <b-form-input class="text-right" type="text" v-model="filtro.numeroFatura" autocomplete="on" @keyup="limpaTela(); mascaraInteiro($event)" />
                    </b-form-group>
                </b-col>

                <b-col md="1">
                    <b-form-group class="text-white" label="." label-cols="12" label-size="lg">
                        <b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
                            <b-icon icon="search" scale="1" />
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-form-row>
        </form>
        <!-- #endregion -->

        <!-- #region listagem/paginacao -->
        <div v-if="exibeRelatorio" class="mt-4">
            <!-- #region opcoes -->
            <div class="">
                <span class="mb-4 float-right mx-2">
                    <b-button @click.prevent="imprimeCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
                </span>
                <span class="mb-4 float-right mx-2">
                    <b-button class="btn-vermelho" type="button" @click="imprimeDetalhadoTodos()">Exportar PDF (detalhado)</b-button>
                </span>
                <span class="mb-4 float-right mx-2">
                    <b-button class="btn-vermelho" type="button" @click="imprimeResumoTodos()">Exportar PDF (resumo)</b-button>
                </span>
            </div>
            <!-- #endregion -->

            <b-table id="tabelaRelatorio" class="text-nowrap h5 mt-4"
                     :items="tabelaRelatorio"
                     :fields="camposRelatorio"
                     :per-page="totalPagina"
                     :current-page="paginaAtual"
                     small
                     hover
                     responsive
                     no-footer-sorting
                     foot-clone>
                <!--===================================================================================
                  celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

                  obj.index = indice da linha
                  obj.field = nome do campo/coluna/chave json
                  obj.item  = objeto json bruto
                  obj.value = o valor da chave json com tipo original mantido e sem tratamento

                  para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
                ====================================================================================-->
                <!--<template #cell()="obj">
                  {{ obj.field }}
                  {{ obj.item }}
                  {{ obj.value }}
                </template>-->

                <!-- #region celulas customizadas/formatadas -->
                <template #head(selecao)="obj">
                    <b-form-checkbox v-model="optMarcarTodos" size="lg" switch v-b-tooltip.hover.right="'Marcar/desmarcar todas'" @change="marcarTodos($event)" />
                </template>

                <template #cell(selecao)="obj">
                    <b-form-checkbox v-if="obj.item.situacao !== VS_STATUS_FATURA_CANCELADA" name="pagador" size="lg" v-model="faturasSelecionadas" switch :value="obj.item.id" v-b-tooltip.hover.right="'Marcar/desmarcar'" @change="marcouTodos()" />
                </template>

                <template #cell(numeroFatura)="obj">
                    <a v-if="obj.value" class="text-black" @click.prevent="imprimeResumo(obj.item)" v-b-tooltip.hover.left="'Imprimir PDF resumo'">
                        <b-icon icon="printer" class="text-info" />
                    </a>&nbsp;

                    <a v-if="obj.value" class="text-black" @click.prevent="imprimeDetalhado(obj.item)" v-b-tooltip.hover.left="'Imprimir PDF detalhado'">
                        <b-icon icon="printer" class="text-secondary" />
                    </a>&nbsp;

                    <a v-if="obj.value" class="text-black" @click.prevent="imprimeDetalhadoCSV(obj.item)" v-b-tooltip.hover.left="'Imprimir CSV detalhado'">
                        <b-icon icon="printer" class="text-success" />
                    </a>&nbsp;

                    <span>{{ obj.value }}</span>
                </template>

                <template #cell(situacao)="obj">
                    <div class="d-flex justify-content-between">
                        <a v-if="obj.value" class="text-black" @click.prevent="mostraHistorico(obj.item)" v-b-tooltip.hover.left="'Mostrar histórico'">
                            <span v-if="obj.value === VS_STATUS_FATURA_ABERTA">
                                <b-icon icon="exclamation-octagon-fill" variant="warning" /> Aberta
                            </span>
                            <span v-else-if="obj.value === VS_STATUS_FATURA_PAGA">
                                <b-icon icon="currency-dollar" variant="success" /> Paga
                            </span>
                            <span v-else-if="obj.value === VS_STATUS_FATURA_PAGAMENTO_PARCIAL">
                                <b-icon icon="currency-dollar" class="cor-laranja" /> Pagto. Parcial
                            </span>
                            <span v-else-if="obj.value === VS_STATUS_FATURA_VENCIDA">
                                <b-icon icon="calendar-date-fill" variant="danger" /> Vencida
                            </span>
                            <span v-else-if="obj.value === VS_STATUS_FATURA_CANCELADA">
                                <b-icon icon="exclamation-octagon-fill" variant="secondary" /> Excluída
                            </span>
                        </a>

                        <span v-if="obj.value === VS_STATUS_FATURA_ABERTA && [VS_PERFIL_FILIAL, VS_PERFIL_GERENTE].includes($root.sessao.perfil)" class="ml-3">
                            <a v-if="obj.value" class="text-black" @click.prevent="mostraExclusao(obj.item)" v-b-tooltip.hover.left="'Excluir'">
                                <b-icon icon="trash" class="text-danger" />
                            </a>
                        </span>
                    </div>
                </template>

                <template #cell(dataCriacao)="obj">
                    <span>{{ $vsFormataData(obj.value) }}</span>
                </template>

                <template #cell(periodo)="obj">
                    {{ $vsFormataData(obj.item.dataFinal) }}
                </template>

                <template #cell(dataVencimento)="obj">
                    <span v-if="obj.item.numeroFatura > 0">{{ $vsFormataData(obj.value) }}</span>
                </template>

                <template #cell(valorTotal)="obj">
                    {{ $vsFormataMoeda(obj.value) }}
                </template>

                <template #cell(valorPago)="obj">
                    {{ $vsFormataMoeda(obj.value) }}
                </template>

                <template #cell(valorRestante)="obj">
                    {{ $vsFormataMoeda(obj.item.valorRestante) }}
                </template>
                <!-- #endregion -->

                <!-- #region rodape com totais -->
                <template #foot(pagadorNome)>
                    Total
                </template>
                <template #foot()>
                    &nbsp;
                </template>
                <template #foot(valorTotal)>
                    <span class="float-right">R$ {{ $vsFormataMoeda(valorTotal()) }}</span>
                </template>
                <template #foot(valorPago)>
                    <span class="float-right">R$ {{ $vsFormataMoeda(valorPago()) }}</span>
                </template>
                <template #foot(valorRestante)>
                    <span class="float-right">R$ {{ $vsFormataMoeda(valorRestante()) }}</span>
                </template>
                <!-- #endregion -->
            </b-table>

            <!-- #region paginacao -->
            <div class="h5">
                <span style="padding-left: 5px;padding-right: 20px;">
                    <b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
                </span>
                <span> Total de Regitros: {{ $vsFormataNumero(tabelaRelatorio.length) }}</span>
                <b-pagination class="mt-3 d-flex justify-content-center"
                              :total-rows="tabelaRelatorio.length"
                              :per-page="totalPagina"
                              aria-controls="tabelaRelatorio"
                              v-model="paginaAtual" />
            </div>
            <!-- #endregion -->
        </div>
        <!-- #endregion -->

        <!-- #region modais -->
        <b-modal id="modalHistorico" title="Histórico de pagamentos da fatura" size="lg">
            <div v-if="faturaHistorico" class="h5">
                <!-- #region descritivos -->
                <div class="mb-3 float-right text-right">
                    Data: {{ $vsFormataData(faturaHistorico.dataCriacao, true) }}<br>
                    Período: {{ $vsFormataData(faturaHistorico.dataInicial) }} à {{ $vsFormataData(faturaHistorico.dataFinal) }}<br>
                    Vencimento: {{ $vsFormataData(faturaHistorico.dataVencimento) }}
                </div>
                <div class="mb-3">Fatura Nº: {{ faturaHistorico.numeroFatura }}</div>
                <div class="mb-3">Pagador: {{ faturaHistorico.pagadorNome }}</div>
                <div class="mb-3" v-if="faturaHistorico.situacao === VS_STATUS_FATURA_CANCELADA">
                    Excluído por: {{ faturaHistorico.usuarioCancelamentoNome }} em {{ $vsFormataData(faturaHistorico.dataCancelamento) }}
                </div>
                <div class="mb-5"></div>
                <!-- #endregion -->
                <!-- #region pagamentos -->
                <div v-if="exibeOpcaoPagamento()" class="mb-4">
                    <form>
                        <!-- #region campos -->
                        <b-form-row class="d-flex justify-content-center">
                            <b-col md="3">
                                <b-form-group class="form-input-cinza" label="Data Pagto." label-cols="12" label-size="lg">
                                    <b-form-datepicker locale="pt-BR" v-model="pagamento.dataPagamento" placeholder="dd/mm/aaaa" :date-format-options="formatoData" :min="faturaHistorico.dataCorte.slice(0, 10)" :max="new Date().toISOString().slice(0, 10)" />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group class="form-input-cinza" label="Valor Total" label-cols="12" label-size="lg">
                                    <b-form-input class="text-right" type="text" :value="$vsFormataMoeda(faturaHistorico.valorTotal)" disabled />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group class="form-input-cinza" label="Valor Pago" label-cols="12" label-size="lg">
                                    <b-form-input class="text-right" type="text" v-model="pagamento.valor" @keyup="liberaAcoesPagamento(); mascaraMoeda($event)" />
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row class="d-flex justify-content-center">
                            <b-col md="9">
                                <b-form-group class="form-input-cinza" label="Descrição (opcional)" label-cols="12" label-size="lg">
                                    <b-form-input type="text" v-model="pagamento.descricao" />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <!-- #endregion -->
                        <!-- #region acoes -->
                        <b-form-row class="d-flex justify-content-center">
                            <b-col md="3">
                                <button v-if="liberaPagamentoTotal" type="button" class="btn-verde" @click="pagaFatura()">Marcar Pago</button>
                                <button v-else type="button" class="btn-desabilitado">Marcar Pago</button>
                            </b-col>
                            <b-col md="1" />
                            <b-col md="3">
                                <button v-if="liberaPagamentoParcial" type="button" class="btn-laranja" @click="pagaFatura()">Pagto. Parcial</button>
                                <button v-else type="button" class="btn-desabilitado">Pagto. Parcial</button>
                            </b-col>
                        </b-form-row>
                        <!-- #endregion -->
                    </form>
                </div>
                <!-- #endregion -->
                <!-- #region historico -->
                <div v-if="exibeHistorico">
                    <b-table id="tabelaHistorico" class="text-nowrap h5 mt-4"
                             :items="tabelaHistorico"
                             :fields="camposHistorico"
                             small
                             hover
                             responsive
                             borderless>
                        <!--===================================================================================
                            celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

                            obj.index = indice da linha
                            obj.field = nome do campo/coluna/chave json
                            obj.item  = objeto json bruto
                            obj.value = o valor da chave json com tipo original mantido e sem tratamento

                            para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
                        ====================================================================================-->
                        <!--<template #cell()="obj">
                            {{ obj.field }}
                            {{ obj.item }}
                            {{ obj.value }}
                        </template>-->
                        <!-- #region celulas customizadas/formatadas -->
                        <template #cell(dataPagamento)="obj">
                            {{ $vsFormataData(obj.value) }}
                        </template>

                        <template #cell(valor)="obj">
                            {{ $vsFormataMoeda(obj.value) }}
                        </template>
                        <!-- #endregion -->
                    </b-table>
                </div>
                <!-- #endregion -->
            </div>

            <!-- #region rodape -->
            <template #modal-footer="{ hide }">
                <div class="w-100">
                    <span class="h5 float-left text-danger font-weight-bold">
                        Pago até o momento: R$ {{ $vsFormataMoeda(faturaHistorico.valorPago) }}<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Saldo restante: R$ {{ $vsFormataMoeda(faturaHistorico.valorRestante) }}
                    </span>

                    <b-button type="button" class="float-right ml-2" variant="secondary" @click="hide()">Sair</b-button>
                </div>
            </template>
            <!-- #endregion -->
        </b-modal>

        <b-modal id="modalExclusao" title="Alerta" size="">
            <div v-if="faturaHistorico" class="h5">
                <!-- #region descritivos -->
                <div class="mb-4 text-danger font-weight-bold">Deseja mesmo excluir essa fatura?</div>
                <div>
                    <div class="mb-3 float-right text-right">
                        Período: {{ $vsFormataData(faturaHistorico.dataInicial) }}<br>
                        {{ $vsFormataData(faturaHistorico.dataFinal) }}
                    </div>
                    <div class="mb-3">Fatura Nº: {{ faturaHistorico.numeroFatura }}</div>
                    <div class="mb-5">Pagador: {{ faturaHistorico.pagadorNome }}</div>
                </div>
                <!-- #endregion -->
                <!-- #region confirmacao -->
                <div>
                    <span class="float-right">
                        <b-form-input type="text" v-model="textoExclusao" />
                    </span>
                    Digite <span class="font-weight-bold">EXCLUÍDO</span>
                </div>
                <!-- #endregion -->
            </div>

            <!-- #region rodape -->
            <template #modal-footer="{ hide }">
                <div class="w-100">
                    <b-button type="button" class="float-right ml-2" variant="danger" @click="excluiFatura()">Excluir</b-button>
                    <b-button type="button" class="float-right ml-2" variant="secondary" @click="hide()">Cancelar</b-button>
                </div>
            </template>
            <!-- #endregion -->
        </b-modal>
        <!-- #endregion -->
    </div>
</template>

<script>
    import { ModelSelect } from "vue-search-select"

    export default {
        // #region configs
        name: "faturamento-cliente-gestao",
        path: "/faturamento-cliente-gestao",
        requireAuth: true,
        components: { ModelSelect },
        // #endregion

        // #region dados
        data() {
            return {
                OPT_TODOS: "0",
                formatoData: { year: 'numeric', month: 'numeric', day: 'numeric', },
                textoExclusao: "",
                liberaPagamentoTotal: false,
                liberaPagamentoParcial: false,
                filtro: {
                    tipoData: null,
                    dataInicial: new Date().toISOString().substring(0, 10), // hoje
                    dataFinal: new Date().toISOString().substring(0, 10), // hoje
                    idPagador: null,
                    situacao: null,
                    numeroFatura: "",
                },
                pagamento: {
                    dataPagamento: new Date().toISOString().substring(0, 10), // hoje
                    descricao: "",
                    valor: 0,
                },
                camposRelatorio: [
                    { key: "selecao", label: "", sortable: false, thClass: "text-left", },
                    { key: "pagadorNome", label: "Pagador", sortable: true, },
                    { key: "numeroFatura", label: "Fatura", sortable: true, },
                    { key: "situacao", label: "Situação", sortable: true, },
                    { key: "dataCriacao", label: "Data de criação", sortable: true, },
                    { key: "periodo", label: "Data de corte", sortable: true, },
                    { key: "dataVencimento", label: "Vencimento", sortable: true, },
                    { key: "valorTotal", label: "Total", sortable: true, tdClass: "text-right", },
                    { key: "valorPago", label: "Pago", sortable: true, tdClass: "text-right", },
                    { key: "valorRestante", label: "Restante", sortable: true, tdClass: "text-right", },
                ],
                camposHistorico: [
                    { key: "dataPagamento", label: "Data Pagamento", sortable: false, tdClass: "text-center", },
                    { key: "valor", label: "Valor Pago", sortable: false, tdClass: "text-right", },
                    { key: "descricao", label: "Descrição", sortable: false, },
                ],
                comboTipoData: [],
                comboPagador: [],
                comboSituacao: [],
                faturaHistorico: null,
                exibeRelatorio: false,
                exibeHistorico: false,
                optMarcarTodos: false,
                faturasSelecionadas: [],
                tabelaRelatorio: [],
                tabelaHistorico: [],
                paginaAtual: 1,
                totalPagina: 100,
                comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
            }
        },
        // #endregion

        // #region triggers
        created() {
            this.$vsDefineAcesso([
                this.VS_PERFIL_FILIAL,
                this.VS_PERFIL_GERENTE,
                this.VS_PERFIL_CLIENTE,
            ])
        },
        mounted() {
            this.bootstrap()
        },
        // #endregion

        // #region metodos
        methods: {
            async bootstrap() {
                if (this.$root.sessao.perfil === this.VS_PERFIL_CLIENTE) {
                    this.camposRelatorio[1].label = "Ponto de Atendimento"
                }

                this.defineTipoData()
                this.defineComboSituacao()

                await this.listaPagador()
                await this.listaFaturas()
            },
            mascaraMoeda(evt) {
                evt.target.value = this.$vsFormataMoeda(evt.target.value)
            },
            mascaraInteiro(evt) {
                evt.target.value = this.$vsLimpaFormatacao(evt.target.value)
            },
            montaQueryFiltro() {
                let filtro = this.filtro
                let sessao = this.$root.sessao
                let query = `idFilial=${sessao.id}`

                if (sessao.perfil === this.VS_PERFIL_GERENTE) {
                    query = `idFilial=${sessao.filial.id}`
                }
                if (sessao.perfil === this.VS_PERFIL_CLIENTE) {
                    query = `idPagador=${sessao.id}`
                }

                query += `&faturaDePara=${this.VS_FATURA_DEPARA_FILIAL_CLIENTE}`

                if (parseInt(filtro.numeroFatura) > 0) {
                    query += `&numeroFatura=${filtro.numeroFatura}`
                } else {
                    if (filtro.idPagador && filtro.idPagador !== this.OPT_TODOS) {
                        query += `&idPagador=${filtro.idPagador}`
                    }
                    if (filtro.situacao !== this.OPT_TODOS) {
                        query += `&situacao=${filtro.situacao}`
                    }

                    query += `&tipoData=${filtro.tipoData}`
                    query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
                    query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`
                }
                return query
            },
            async listaPagador() {
                let sessao = this.$root.sessao

                if (sessao.perfil === this.VS_PERFIL_CLIENTE) {
                    this.definecomboPagador([sessao])
                    return
                }

                let query = `idFilial=${sessao.id}`

                if (sessao.perfil === this.VS_PERFIL_GERENTE) {
                    query = `idFilial=${sessao.filial.id}`
                }
                if (sessao.perfil !== this.VS_PERFIL_CONTRATANTE) {
                    query += `&idContratante=${sessao.contratante.id}`
                }

                let resp = await this.$vsApiRest("GET", `/usuarios/clientes/?${query}`)

                if (resp.status === 200) {
                    this.definecomboPagador(resp.data)
                }
            },
            async listaFaturas() {
                if (!this.validacoesFiltro()) {
                    return
                }

                let query = this.montaQueryFiltro()

                this.limpaTela()

                let resp = await this.$vsApiRest("GET", `/faturamento/relatorios/gestao/?${query}`)

                if (resp.status === 200) {
                    this.tabelaRelatorio = resp.data
                    this.exibeRelatorio = (resp.data.length > 0)
                }
            },
            async listaHistorico(obj) {
                let query = `idFatura=${obj.id}`

                this.tabelaHistorico = []
                this.exibeHistorico = false

                let resp = await this.$vsApiRest("GET", `/faturamento/historicos/?${query}`)

                if (resp.status === 200) {
                    this.tabelaHistorico = resp.data
                    this.exibeHistorico = (resp.data.length > 0)
                }
            },
            definecomboPagador(pagadores) {
                pagadores.forEach(e => {
                    e.text = e.isAtivo ? e.nome : `${e.nome} (inativo)`
                    e.value = e.id
                })
                this.comboPagador = pagadores.filter(el => !el.isExcluido)
                this.comboPagador.unshift({ value: this.OPT_TODOS, text: "Todas" }) // primeira opcao
                this.filtro.idPagador = this.OPT_TODOS
            },
            defineComboSituacao() {
                this.comboSituacao = [
                    { value: this.OPT_TODOS, text: "Todas" }, // primeira opcao
                    { value: this.VS_STATUS_FATURA_ABERTA, text: "Aberta" },
                    { value: this.VS_STATUS_FATURA_PAGA, text: "Paga" },
                    { value: this.VS_STATUS_FATURA_PAGAMENTO_PARCIAL, text: "Pagto. Parcial" },
                    { value: this.VS_STATUS_FATURA_VENCIDA, text: "Vencida" },
                    { value: this.VS_STATUS_FATURA_CANCELADA, text: "Excluída" },
                ]
                this.filtro.situacao = this.OPT_TODOS
            },
            defineTipoData() {
                this.comboTipoData = [
                    { value: 2, text: "Vencimento" },
                    { value: 1, text: "Período de corte" },
                ]
                this.filtro.tipoData = this.comboTipoData[0].value
            },
            limpaTela() {
                this.exibeRelatorio = false
                this.tabelaRelatorio = []
                this.faturasSelecionadas = []
                this.optMarcarTodos = false
            },
            valorTotal() {
                return this.tabelaRelatorio.reduce((total, obj) => total + obj.valorTotal, 0)
            },
            valorPago() {
                return this.tabelaRelatorio.reduce((total, obj) => total + obj.valorPago, 0)
            },
            valorRestante() {
                return this.tabelaRelatorio.reduce((total, obj) => total + (obj.valorRestante), 0)
            },
            marcarTodos(marcou) {
                if (marcou) {
                    this.faturasSelecionadas = this.tabelaRelatorio.filter(el => el.situacao !== this.VS_STATUS_FATURA_CANCELADA).map(el => el.id)
                } else {
                    this.faturasSelecionadas = []
                }
            },
            marcouTodos() {
                this.optMarcarTodos = (this.tabelaRelatorio.length === this.faturasSelecionadas.length)
            },
            async imprimeResumo(obj) {
                let resp = await this.$vsApiRest("POST", `/faturamento/impressao-resumo-fatura/`, [obj.id], {}, "arraybuffer")

                if (resp.status === 200) {
                    this.$vsDownload(resp.data, `relatorio-fatura-resumido-${new Date().getTime()}.zip`)
                }
            },
            async imprimeResumoTodos() {
                if (this.faturasSelecionadas.length === 0) {
                    this.$vsNotificacao("Selecione ao menos uma fatura", "warning")
                    return
                }
                let resp = await this.$vsApiRest("POST", `/faturamento/impressao-resumo-fatura/`, this.faturasSelecionadas, {}, "arraybuffer")

                if (resp.status === 200) {
                    this.$vsDownload(resp.data, `relatorio-fatura-resumido-${new Date().getTime()}.zip`)
                }
            },
            async imprimeDetalhado(obj) {
                let resp = await this.$vsApiRest("POST", `/faturamento/impressao-detalhado-fatura/`, [obj.id], {}, "arraybuffer")

                if (resp.status === 200) {
                    this.$vsDownload(resp.data, `relatorio-fatura-detalhado-${new Date().getTime()}.zip`)
                }
            },
            async imprimeDetalhadoCSV(obj) {
                let resp = await this.$vsApiRest("POST", `/faturamento/impressao-detalhado-fatura-csv/?idFatura=${obj.id}`, {}, {}, "arraybuffer")

                if (resp.status === 200) {
                    this.$vsDownload(resp.data, `relatorio-fatura-detalhado-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
                }
            },
            async imprimeDetalhadoTodos() {
                if (this.faturasSelecionadas.length === 0) {
                    this.$vsNotificacao("Selecione ao menos uma fatura", "warning")
                    return
                }

                let resp = await this.$vsApiRest("POST", `/faturamento/impressao-detalhado-fatura/`, this.faturasSelecionadas, {}, "arraybuffer")

                if (resp.status === 200) {
                    this.$vsDownload(resp.data, `relatorio-fatura-detalhado-${new Date().getTime()}.zip`)
                }
            },
            mostraExclusao(obj) {
                this.faturaHistorico = obj
                this.$bvModal.show("modalExclusao")
            },
            mostraHistorico(obj) {
                this.listaHistorico(obj)
                this.limpaPagamento()

                this.faturaHistorico = obj
                this.$bvModal.show("modalHistorico")
            },
            exibeOpcaoPagamento() {
                let fat = this.faturaHistorico
                let sessao = this.$root.sessao
                let permissao = [
                    this.VS_PERFIL_FILIAL,
                    this.VS_PERFIL_GERENTE
                ].includes(sessao.perfil)
                let status = [
                    this.VS_STATUS_FATURA_ABERTA,
                    this.VS_STATUS_FATURA_PAGAMENTO_PARCIAL,
                    this.VS_STATUS_FATURA_VENCIDA
                ].includes(fat.situacao)

                return (permissao && status && fat.valorPago < fat.valorTotal)
            },
            liberaAcoesPagamento() {
                let fat = this.faturaHistorico
                let pag = this.pagamento
                let valor = this.$vsConverteMoeda(this.$vsFormataMoeda(pag.valor))

                this.liberaPagamentoTotal = (valor > 0 && valor + fat.valorPago === fat.valorTotal)
                this.liberaPagamentoParcial = (valor > 0 && valor + fat.valorPago < fat.valorTotal)
            },
            async pagaFatura() {
                let fat = this.faturaHistorico
                let pag = this.pagamento
                let valorPagto = this.$vsConverteMoeda(pag.valor)
                let valorPago = fat.valorPago + valorPagto

                if (valorPagto <= 0) {
                    this.$vsNotificacao("Valor do pagamento não pode ser negativo", "warning")
                    return
                }
                if (valorPago > fat.valorTotal) {
                    this.$vsNotificacao("O valor pago não pode ultrapassar o valor total da fatura", "warning")
                    return
                }

                let sessao = this.$root.sessao
                let req = {
                    idContratante: sessao.contratante.id,
                    idFilial: fat.idFilial,
                    idPagador: fat.idPagador,
                    idOperador: sessao.id,
                    idFatura: fat.id,
                    dataPagamento: this.$vsConverteDataUTC(`${pag.dataPagamento}T00:00:00`),
                    descricao: pag.descricao.trim(),
                    valor: valorPagto,
                    faturaDePara: this.VS_FATURA_DEPARA_FILIAL_CLIENTE,
                }
                let resp = await this.$vsApiRest("POST", `/faturamento/historicos/`, req)

                if (resp.status === 200) {
                    fat.valorPago = valorPago
                    fat.valorRestante -= valorPagto

                    this.$vsNotificacao(`Pagamento lançado com sucesso`, "success")
                    this.limpaPagamento()
                    this.listaFaturas()

                    if (valorPago === fat.valorTotal) {
                        this.$bvModal.hide("modalHistorico")
                    } else {
                        this.listaHistorico(fat)
                    }
                }
            },
            async excluiFatura() {
                if (this.textoExclusao !== "EXCLUÍDO") {
                    this.$vsNotificacao("Digite o texto exatamente igual, sem espaços e com letras maiúsculas", "warning")
                    return
                }

                let obj = this.faturaHistorico
                let resp = await this.$vsApiRest("DELETE", `/faturamento/exclusao/${obj.id}`)

                if (resp.status === 200) {
                    if (resp.data === false) {
                        this.$vsNotificacao(`Não foi possível excluir a Fatura ${obj.numeroFatura} no momento, tente novamente mais tarde`, "warning")
                        return
                    }
                    this.$vsNotificacao(`Fatura ${obj.numeroFatura} excluída com sucesso`, "success")
                    this.$bvModal.hide("modalExclusao")
                    this.listaFaturas()
                }
            },
            limpaPagamento() {
                let pag = this.pagamento
                pag.dataPagamento = new Date().toISOString().substring(0, 10)
                pag.descricao = ""
                pag.valor = 0
            },
            async imprimeCSV() {
                let query = this.montaQueryFiltro()
                let resp = await this.$vsApiRest("GET", `/faturamento/impressao-gestao-csv/?${query}`, {}, {}, "arraybuffer")

                if (resp.status === 200) {
                    this.$vsDownload(resp.data, `relatorio-faturamento-gestao-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
                }
            },
            validacoesFiltro() {
                let form = this.filtro
                let dtInicial = new Date(`${form.dataInicial}T00:00:00`)
                let dtFinal = new Date(`${form.dataFinal}T23:59:59`)
                let ok = true

                if (dtFinal < dtInicial) {
                    this.$vsNotificacao("Data final não pode ser menor que a data inicial", "warning")
                    ok = false
                }
                return ok
            },
        },
        // #endregion
    }
</script>
